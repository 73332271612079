export const banks = [
  {
    bankName: "9 payment service Bank",
    bankCode: "120001",
  },
  {
    bankName: "AB MICROFINANCE BANK",
    bankCode: "090270",
  },
  {
    bankName: "ABBEY MORTGAGE BANK",
    bankCode: "070010",
  },
  {
    bankName: "ABOVE ONLY MICROFINANCE BANK",
    bankCode: "090260",
  },
  {
    bankName: "ABU MICROFINANCE BANK",
    bankCode: "090197",
  },
  {
    bankName: "ACCESS BANK",
    bankCode: "000014",
  },
  {
    bankName: "ACCESSMONEY",
    bankCode: "100013",
  },
  {
    bankName: "ACCION MFB",
    bankCode: "090134",
  },
  {
    bankName: "ADDOSSER MFB",
    bankCode: "090160",
  },
  {
    bankName: "ADEYEMI COLLEGE STAFF MICROFINANCE BANK",
    bankCode: "090268",
  },
  {
    bankName: "ADVANS LA FAYETTE MFB",
    bankCode: "090155",
  },
  {
    bankName: "AG MORTGAGE BANK PLC",
    bankCode: "100028",
  },
  {
    bankName: "AGOSASA MICROFINANCE BANK",
    bankCode: "090371",
  },
  {
    bankName: "AL-BARKAH MFB",
    bankCode: "090133",
  },
  {
    bankName: "ALEKUN MICROFINANCE BANK",
    bankCode: "090259",
  },
  {
    bankName: "ALERT MFB",
    bankCode: "090297",
  },
  {
    bankName: "ALHAYAT MFB",
    bankCode: "090277",
  },
  {
    bankName: "ALLWORKERS MFB",
    bankCode: "090131",
  },
  {
    bankName: "ALPHAKAPITAL MFB",
    bankCode: "090169",
  },
  {
    bankName: "AMJU MFB",
    bankCode: "090180",
  },
  {
    bankName: "AMML MFB",
    bankCode: "090116",
  },
  {
    bankName: "APEKS MICROFINANCE BANK",
    bankCode: "090143",
  },
  {
    bankName: "APPLE  MICROFINANCE BANK",
    bankCode: "090376",
  },
  {
    bankName: "ARISE MFB",
    bankCode: "090282",
  },
  {
    bankName: "ASOSAVINGS",
    bankCode: "090001",
  },
  {
    bankName: "ASSETS Microfinance Bank",
    bankCode: "090473",
  },
  {
    bankName: "ASTRAPOLARIS MFB",
    bankCode: "090172",
  },
  {
    bankName: "ATBU  Microfinance Bank",
    bankCode: "090451",
  },
  {
    bankName: "AUCHI MICROFINANCE BANK",
    bankCode: "090264",
  },
  {
    bankName: "Abucoop  Microfinance Bank",
    bankCode: "090424",
  },
  {
    bankName: "Ada MFB",
    bankCode: "090483",
  },
  {
    bankName: "Afekhafe MFB",
    bankCode: "090292",
  },
  {
    bankName: "Afemai Microfinance Bank",
    bankCode: "090518",
  },
  {
    bankName: "Alvana Microfinance Bank",
    bankCode: "090489",
  },
  {
    bankName: "Amac Microfinance Bank",
    bankCode: "090394",
  },
  {
    bankName: "Ampersand Microfinance bank",
    bankCode: "090529",
  },
  {
    bankName: "Anchorage MFB",
    bankCode: "090476",
  },
  {
    bankName: "Aniocha MFB",
    bankCode: "090469",
  },
  {
    bankName: "Arca Payments",
    bankCode: "110011",
  },
  {
    bankName: "Assets Matrix MFB",
    bankCode: "090287",
  },
  {
    bankName: "Avuenegbe MFB",
    bankCode: "090478",
  },
  {
    bankName: "BAINES CREDIT MFB",
    bankCode: "090188",
  },
  {
    bankName: "BALOGUN GAMBARI MFB",
    bankCode: "090326",
  },
  {
    bankName: "BAYERO MICROFINANCE BANK",
    bankCode: "090316",
  },
  {
    bankName: "BC KASH MFB",
    bankCode: "090127",
  },
  {
    bankName: "BETA-ACCESS YELLO",
    bankCode: "100052",
  },
  {
    bankName: "BIPC MICROFINANCE BANK",
    bankCode: "090336",
  },
  {
    bankName: "BOCTRUST MICROFINANCE BANK",
    bankCode: "090117",
  },
  {
    bankName: "BOI MF Bank",
    bankCode: "090444",
  },
  {
    bankName: "BOSAK MFB",
    bankCode: "090176",
  },
  {
    bankName: "BOWEN MFB",
    bankCode: "090148",
  },
  {
    bankName: "BRENT MORTGAGE BANK",
    bankCode: "070015",
  },
  {
    bankName: "BRETHREN MICROFINANCE BANK",
    bankCode: "090293",
  },
  {
    bankName: "BRIGHTWAY MFB",
    bankCode: "090308",
  },
  {
    bankName: "BUBAYERO Microfinance Bank",
    bankCode: "090512",
  },
  {
    bankName: "Balogun Fulani  Microfinance Bank",
    bankCode: "090181",
  },
  {
    bankName: "Banex Microfinance Bank",
    bankCode: "090425",
  },
  {
    bankName: "Benysta Microfinance Bank",
    bankCode: "090413",
  },
  {
    bankName: "Bluewhales  Microfinance Bank",
    bankCode: "090431",
  },
  {
    bankName: "Boji Boji Microfinance Bank",
    bankCode: "090494",
  },
  {
    bankName: "Bonghe Microfinance Bank",
    bankCode: "090319",
  },
  {
    bankName: "Borgu MFB",
    bankCode: "090395",
  },
  {
    bankName: "Borno Renaissance Microfinance bank",
    bankCode: "090508",
  },
  {
    bankName: "Boromu Microfinance bank",
    bankCode: "090501",
  },
  {
    bankName: "Borstal Microfinance Bank",
    bankCode: "090454",
  },
  {
    bankName: "Bridgeway Microfinance Bank",
    bankCode: "090393",
  },
  {
    bankName: "Business Support Microfinance Bank",
    bankCode: "090406",
  },
  {
    bankName: "CAPITALMETRIQ SWIFT MICROFINANCE BANK",
    bankCode: "090509",
  },
  {
    bankName: "CARBON",
    bankCode: "100026",
  },
  {
    bankName: "CARETAKER Microfinance Bank",
    bankCode: "090472",
  },
  {
    bankName: "CASHCONNECT   MICROFINANCE BANK",
    bankCode: "090360",
  },
  {
    bankName: "CELLULANT",
    bankCode: "100005",
  },
  {
    bankName: "CEMCS MFB",
    bankCode: "090154",
  },
  {
    bankName: "CHIKUM MICROFINANCE BANK",
    bankCode: "090141",
  },
  {
    bankName: "CIT MICROFINANCE BANK",
    bankCode: "090144",
  },
  {
    bankName: "CITI BANK",
    bankCode: "000009",
  },
  {
    bankName: "COASTLINE MICROFINANCE BANK",
    bankCode: "090374",
  },
  {
    bankName: "CONSUMER  MFB",
    bankCode: "090130",
  },
  {
    bankName: "CONTEC GLOBAL",
    bankCode: "100032",
  },
  {
    bankName: "COOP Mortgage Bank",
    bankCode: "070021",
  },
  {
    bankName: "CORONATION",
    bankCode: "060001",
  },
  {
    bankName: "COVENANT MFB",
    bankCode: "070006",
  },
  {
    bankName: "CREDIT AFRIQUE MFB",
    bankCode: "090159",
  },
  {
    bankName: "CROWDFORCE",
    bankCode: "110017",
  },
  {
    bankName: "Calabar Microfinance Bank",
    bankCode: "090415",
  },
  {
    bankName: "Capricorn Digital",
    bankCode: "110023",
  },
  {
    bankName: "Capstone MF Bank",
    bankCode: "090445",
  },
  {
    bankName: "Catland Microfinance Bank",
    bankCode: "090498",
  },
  {
    bankName: "Chanelle Bank",
    bankCode: "090397",
  },
  {
    bankName: "Changan RTS Microfinance Bank",
    bankCode: "090470",
  },
  {
    bankName: "Chase Microfinance bank",
    bankCode: "090523",
  },
  {
    bankName: "Cherish Microfinance Bank",
    bankCode: "090440",
  },
  {
    bankName: "Chibueze Microfinance Bank",
    bankCode: "090416",
  },
  {
    bankName: "Chukwunenye  Microfinance Bank",
    bankCode: "090490",
  },
  {
    bankName: "Cintrust MFB",
    bankCode: "090480",
  },
  {
    bankName: "Citizen Trust Microfinance Bank Ltd",
    bankCode: "090343",
  },
  {
    bankName: "Cloverleaf  MFB",
    bankCode: "090511",
  },
  {
    bankName: "CoalCamp Microfinance Bank",
    bankCode: "090254",
  },
  {
    bankName: "Corestep MICROFINANCE BANK",
    bankCode: "090365",
  },
  {
    bankName: "Crescent Microfinance bank",
    bankCode: "090526",
  },
  {
    bankName: "CrossRiver  Microfinance Bank",
    bankCode: "090429",
  },
  {
    bankName: "Crutech  Microfinance Bank",
    bankCode: "090414",
  },
  {
    bankName: "DAYLIGHT MICROFINANCE BANK",
    bankCode: "090167",
  },
  {
    bankName: "DIAMOND BANK",
    bankCode: "000005",
  },
  {
    bankName: "Davodani  Microfinance Bank",
    bankCode: "090391",
  },
  {
    bankName: "Delta Trust Mortgage bank",
    bankCode: "070023",
  },
  {
    bankName: "E-BARCS MFB",
    bankCode: "090156",
  },
  {
    bankName: "EAGLE FLIGHT MFB",
    bankCode: "090294",
  },
  {
    bankName: "EBSU MICROFINANCE Bank",
    bankCode: "090427",
  },
  {
    bankName: "ECOBANK BANK",
    bankCode: "000010",
  },
  {
    bankName: "ECOBANK XPRESS ACCOUNT",
    bankCode: "100008",
  },
  {
    bankName: "EDFIN MFB",
    bankCode: "090310",
  },
  {
    bankName: "EK-Reliable Microfinance Bank",
    bankCode: "090389",
  },
  {
    bankName: "EKONDO MFB",
    bankCode: "090097",
  },
  {
    bankName: "EMERALDS MFB",
    bankCode: "090273",
  },
  {
    bankName: "EMPIRETRUST MICROFINANCE BANK",
    bankCode: "090114",
  },
  {
    bankName: "ESAN MFB",
    bankCode: "090189",
  },
  {
    bankName: "ESO-E MICROFINANCE BANK",
    bankCode: "090166",
  },
  {
    bankName: "ETRANZACT",
    bankCode: "100006",
  },
  {
    bankName: "EVANGEL MFB",
    bankCode: "090304",
  },
  {
    bankName: "EVERGREEN MICROFINANCE BANK",
    bankCode: "090332",
  },
  {
    bankName: "EYOWO MICROFINANCE BANK",
    bankCode: "090328",
  },
  {
    bankName: "FAIRMONEY",
    bankCode: "090551",
  },
  {
    bankName: "FAME Microfinance Bank",
    bankCode: "090330",
  },
  {
    bankName: "FAST Microfinance Bank",
    bankCode: "090179",
  },
  {
    bankName: "FBNQUEST MERCHANT BANK",
    bankCode: "060002",
  },
  {
    bankName: "FCMB",
    bankCode: "000003",
  },
  {
    bankName: "FCMB EASY ACCOUNT",
    bankCode: "100031",
  },
  {
    bankName: "FCMB MFB",
    bankCode: "090409",
  },
  {
    bankName: "FCT MFB",
    bankCode: "090290",
  },
  {
    bankName: "FEDERAL UNIVERSITY DUTSE  MICROFINANCE BANK",
    bankCode: "090318",
  },
  {
    bankName: "FEDERALPOLY NASARAWAMFB",
    bankCode: "090298",
  },
  {
    bankName: "FETS",
    bankCode: "100001",
  },
  {
    bankName: "FFS MICROFINANCE BANK",
    bankCode: "090153",
  },
  {
    bankName: "FIDELITY BANK",
    bankCode: "000007",
  },
  {
    bankName: "FIDELITY MOBILE",
    bankCode: "100019",
  },
  {
    bankName: "FIDFUND MFB",
    bankCode: "090126",
  },
  {
    bankName: "FIMS MFB",
    bankCode: "090507",
  },
  {
    bankName: "FINATRUST MICROFINANCE BANK",
    bankCode: "090111",
  },
  {
    bankName: "FINEX MFB",
    bankCode: "090281",
  },
  {
    bankName: "FIRST BANK OF NIGERIA",
    bankCode: "000016",
  },
  {
    bankName: "FIRST GENERATION MORTGAGE BANK",
    bankCode: "070014",
  },
  {
    bankName: "FIRST MULTIPLE MFB",
    bankCode: "090163",
  },
  {
    bankName: "FIRST OPTION MFB",
    bankCode: "090285",
  },
  {
    bankName: "FIRST ROYAL MICROFINANCE BANK",
    bankCode: "090164",
  },
  {
    bankName: "FIRSTMONIE WALLET",
    bankCode: "100014",
  },
  {
    bankName: "FIRSTTRUST MORGAGES LIMITED",
    bankCode: "090107",
  },
  {
    bankName: "FORTISMOBILE",
    bankCode: "100016",
  },
  {
    bankName: "FSDH",
    bankCode: "400001",
  },
  {
    bankName: "FULL RANGE MFB",
    bankCode: "090145",
  },
  {
    bankName: "FUTO MFB",
    bankCode: "090158",
  },
  {
    bankName: "Federal Polytechnic Nekede Microfinance Bank",
    bankCode: "090398",
  },
  {
    bankName: "Fedeth MFB",
    bankCode: "090482",
  },
  {
    bankName: "Finca Microfinance Bank",
    bankCode: "090400",
  },
  {
    bankName: "Firmus MICROFINANCE BANK",
    bankCode: "090366",
  },
  {
    bankName: "First Heritage MFB",
    bankCode: "090479",
  },
  {
    bankName: "Foresight Microfinance bank",
    bankCode: "090521",
  },
  {
    bankName: "Fortress Microfinance Bank",
    bankCode: "090486",
  },
  {
    bankName: "Futminna Microfinance Bank",
    bankCode: "090438",
  },
  {
    bankName: "GASHUA MICROFINANCE BANK",
    bankCode: "090168",
  },
  {
    bankName: "GATEWAY MORTGAGE BANK",
    bankCode: "070009",
  },
  {
    bankName: "GLOBUS BANK",
    bankCode: "000027",
  },
  {
    bankName: "GLORY MFB ",
    bankCode: "090278",
  },
  {
    bankName: "GMB Microfinance Bank",
    bankCode: "090408",
  },
  {
    bankName: "GOMONEY",
    bankCode: "100022",
  },
  {
    bankName: "GOWANS MFB",
    bankCode: "090122",
  },
  {
    bankName: "GREENBANK MFB",
    bankCode: "090178",
  },
  {
    bankName: "GREENVILLE MICROFINANCE BANK",
    bankCode: "090269",
  },
  {
    bankName: "GROOMING MICROFINANCE BANK",
    bankCode: "090195",
  },
  {
    bankName: "GTBANK PLC",
    bankCode: "000013",
  },
  {
    bankName: "GTI  Microfinance Bank",
    bankCode: "090385",
  },
  {
    bankName: "Garki MFB",
    bankCode: "090484",
  },
  {
    bankName: "GiGinya Microfinance Bank",
    bankCode: "090411",
  },
  {
    bankName: "Giant Stride MFB",
    bankCode: "090475",
  },
  {
    bankName: "Girei MFB",
    bankCode: "090186",
  },
  {
    bankName: "Giwa Microfinance Bank",
    bankCode: "090441",
  },
  {
    bankName: "Good Neighbours Microfinance Bank",
    bankCode: "090467",
  },
  {
    bankName: "Goodnews Microfinance Bank",
    bankCode: "090495",
  },
  {
    bankName: "Grant Microfinance Bank",
    bankCode: "090335",
  },
  {
    bankName: "Greenwich Merchant Bank",
    bankCode: "060004",
  },
  {
    bankName: "Gwong Microfinance bank",
    bankCode: "090500",
  },
  {
    bankName: "HACKMAN MICROFINANCE BANK",
    bankCode: "090147",
  },
  {
    bankName: "HAGGAI MORTGAGE BANK",
    bankCode: "070017",
  },
  {
    bankName: "HALA MFB",
    bankCode: "090291",
  },
  {
    bankName: "HASAL MFB",
    bankCode: "090121",
  },
  {
    bankName: "HERITAGE",
    bankCode: "000020",
  },
  {
    bankName: "Headway MFB",
    bankCode: "090363",
  },
  {
    bankName: "Highland Microfinance Bank",
    bankCode: "090418",
  },
  {
    bankName: "HomeBase Mortgage",
    bankCode: "070024",
  },
  {
    bankName: "HopePSB",
    bankCode: "120002",
  },
  {
    bankName: "IBETO  Microfinance Bank",
    bankCode: "090439",
  },
  {
    bankName: "IBILE MICROFINANCE BANK",
    bankCode: "090118",
  },
  {
    bankName: "IC GLOBALMicrofinance bank",
    bankCode: "090520",
  },
  {
    bankName: "IKENNE MFB",
    bankCode: "090324",
  },
  {
    bankName: "IKIRE MFB",
    bankCode: "090279",
  },
  {
    bankName: "ILASAN MICROFINANCE BANK",
    bankCode: "090370",
  },
  {
    bankName: "IMO MICROFINANCE BANK",
    bankCode: "090258",
  },
  {
    bankName: "IMPERIAL HOMES MORTGAGE BANK",
    bankCode: "100024",
  },
  {
    bankName: "INFINITY MFB",
    bankCode: "090157",
  },
  {
    bankName: "INFINITY TRUST  MORTGAGE BANK",
    bankCode: "070016",
  },
  {
    bankName: "INTELLIFIN",
    bankCode: "100027",
  },
  {
    bankName: "IRL MICROFINANCE BANK",
    bankCode: "090149",
  },
  {
    bankName: "ISALEOYO MICROFINANCE BANK",
    bankCode: "090377",
  },
  {
    bankName: "Ibom fadama Microfinance Bank",
    bankCode: "090519",
  },
  {
    bankName: "Illorin Microfinance Bank",
    bankCode: "090350",
  },
  {
    bankName: "Ilora Microfinance Bank",
    bankCode: "090430",
  },
  {
    bankName: "Imowo Microfinance Bank",
    bankCode: "090417",
  },
  {
    bankName: "Insight Microfinance Bank",
    bankCode: "090434",
  },
  {
    bankName: "Interland MFB",
    bankCode: "090386",
  },
  {
    bankName: "Iperu Microfinance Bank",
    bankCode: "090493",
  },
  {
    bankName: "Ishie  Microfinance Bank",
    bankCode: "090428",
  },
  {
    bankName: "Isuofia MFB",
    bankCode: "090353",
  },
  {
    bankName: "Izon Microfinance Bank",
    bankCode: "090421",
  },
  {
    bankName: "JAIZ BANK",
    bankCode: "000006",
  },
  {
    bankName: "JUBILEELIFE",
    bankCode: "090003",
  },
  {
    bankName: "Jessefield Microfinance Bank",
    bankCode: "090352",
  },
  {
    bankName: "KADPOLY MICROFINANCE BANK",
    bankCode: "090320",
  },
  {
    bankName: "KCMB MFB",
    bankCode: "090191",
  },
  {
    bankName: "KEGOW(CHAMSMOBILE)",
    bankCode: "100036",
  },
  {
    bankName: "KEYSTONE BANK",
    bankCode: "000002",
  },
  {
    bankName: "KONGAPAY",
    bankCode: "100025",
  },
  {
    bankName: "KONTAGORA MFB",
    bankCode: "090299",
  },
  {
    bankName: "KREDI MONEY MICROFINANCE BANK",
    bankCode: "090380",
  },
  {
    bankName: "KUDA MICROFINANCE BANK",
    bankCode: "090267",
  },
  {
    bankName: "Kingdom College  Microfinance Bank",
    bankCode: "090487",
  },
  {
    bankName: "Koraypay",
    bankCode: "110022",
  },
  {
    bankName: "Kwasu MF Bank",
    bankCode: "090450",
  },
  {
    bankName: "LAPO MFB",
    bankCode: "090177",
  },
  {
    bankName: "LAVENDER MICROFINANCE BANK",
    bankCode: "090271",
  },
  {
    bankName: "LBIC MORTGAGE BANK",
    bankCode: "070012",
  },
  {
    bankName: "LEGEND MICROFINANCE BANK",
    bankCode: "090372",
  },
  {
    bankName: "LETSHEGO MICROFINANCE BANK",
    bankCode: "090420",
  },
  {
    bankName: "LIVINGTRUST MORTGAGE BANK PLC",
    bankCode: "070007",
  },
  {
    bankName: "LOVONUS MICROFINANCE BANK",
    bankCode: "090265",
  },
  {
    bankName: "Landgold  Microfinance Bank",
    bankCode: "090422",
  },
  {
    bankName: "Light Microfinance Bank",
    bankCode: "090477",
  },
  {
    bankName: "Links  Microfinance Bank",
    bankCode: "090435",
  },
  {
    bankName: "Lotus Bank",
    bankCode: "000029",
  },
  {
    bankName: "M36",
    bankCode: "100035",
  },
  {
    bankName: "MAINLAND MICROFINANCE BANK",
    bankCode: "090323",
  },
  {
    bankName: "MAINSTREET MFB",
    bankCode: "090171",
  },
  {
    bankName: "MALACHY MFB",
    bankCode: "090174",
  },
  {
    bankName: "MANNY MICROFINANCE BANK",
    bankCode: "090383",
  },
  {
    bankName: "MAYFAIR  MFB",
    bankCode: "090321",
  },
  {
    bankName: "MAYFRESH MORTGAGE BANK",
    bankCode: "070019",
  },
  {
    bankName: "MEGAPRAISE MICROFINANCE BANK",
    bankCode: "090280",
  },
  {
    bankName: "MERIDIAN MFB",
    bankCode: "090275",
  },
  {
    bankName: "MICROCRED MICROFINANCE BANK",
    bankCode: "090136",
  },
  {
    bankName: "MICROVIS MICROFINANCE BANK",
    bankCode: "090113",
  },
  {
    bankName: "MOLUSI MICROFINANCE BANK",
    bankCode: "090362",
  },
  {
    bankName: "MONEYTRUST MFB",
    bankCode: "090129",
  },
  {
    bankName: "MONIEPOINT MICROFINANCE BANK",
    bankCode: "090405",
  },
  {
    bankName: "MUTUAL BENEFITS MFB",
    bankCode: "090190",
  },
  {
    bankName: "MUTUAL TRUST MICROFINANCE BANK",
    bankCode: "090151",
  },
  {
    bankName: "Maintrust MFB",
    bankCode: "090465",
  },
  {
    bankName: "Maritime Microfinance Bank",
    bankCode: "090410",
  },
  {
    bankName: "Mautech  Microfinance Bank",
    bankCode: "090423",
  },
  {
    bankName: "Memphis Microfinance Bank",
    bankCode: "090432",
  },
  {
    bankName: "Microsystems Investment and Development Limited",
    bankCode: "110018",
  },
  {
    bankName: "Midland MFB",
    bankCode: "090192",
  },
  {
    bankName: "MoMo PSB",
    bankCode: "120003",
  },
  {
    bankName: "Monarch Microfinance Bank",
    bankCode: "090462",
  },
  {
    bankName: "Money Master PSB",
    bankCode: "120005",
  },
  {
    bankName: "Moyofade MF Bank",
    bankCode: "090448",
  },
  {
    bankName: "Mozfin Microfinance Bank",
    bankCode: "090392",
  },
  {
    bankName: "NARGATA MFB",
    bankCode: "090152",
  },
  {
    bankName: "NAVY MICROFINANCE BANK",
    bankCode: "090263",
  },
  {
    bankName: "NDIORAH MFB",
    bankCode: "090128",
  },
  {
    bankName: "NEPTUNE MICROFINANCE BANK",
    bankCode: "090329",
  },
  {
    bankName: "NEW GOLDEN PASTURES MICROFINANCE BANK",
    bankCode: "090378",
  },
  {
    bankName: "NEWDAWN MICROFINANCE BANK",
    bankCode: "090205",
  },
  {
    bankName: "NIBSSUSSD PAYMENTS",
    bankCode: "110019",
  },
  {
    bankName: "NIRSAL NATIONAL MICROFINANCE BANK",
    bankCode: "090194",
  },
  {
    bankName: "NNEW WOMEN MFB",
    bankCode: "090283",
  },
  {
    bankName: "NOVA MB",
    bankCode: "060003",
  },
  {
    bankName: "NPF MICROFINANCE BANK",
    bankCode: "070001",
  },
  {
    bankName: "NUTURE MFB",
    bankCode: "090364",
  },
  {
    bankName: "Nasarawa Microfinance bank",
    bankCode: "090349",
  },
  {
    bankName: "NetApps Technology Limited",
    bankCode: "110025",
  },
  {
    bankName: "Nice Microfinance Bank",
    bankCode: "090459",
  },
  {
    bankName: "Nigeria PrisonsMicrofinance bank",
    bankCode: "090505",
  },
  {
    bankName: "Nomba financial services Limited",
    bankCode: "110028",
  },
  {
    bankName: "Nsuk  Microfinance Bank",
    bankCode: "090491",
  },
  {
    bankName: "Numo Microfinance bank",
    bankCode: "090516",
  },
  {
    bankName: "Nwannegadi MFB",
    bankCode: "090399",
  },
  {
    bankName: "OCHE MFB",
    bankCode: "090333",
  },
  {
    bankName: "OHAFIA MFB",
    bankCode: "090119",
  },
  {
    bankName: "OKPOGA MFB",
    bankCode: "090161",
  },
  {
    bankName: "OLABISI ONABANJO UNIVERSITY MICROFINANCE BANK",
    bankCode: "090272",
  },
  {
    bankName: "OLOFIN OWENA Microfinance Bank",
    bankCode: "090468",
  },
  {
    bankName: "OLUCHUKWU Microfinance Bank",
    bankCode: "090471",
  },
  {
    bankName: "OLUYOLE MICROFINANCE BANK",
    bankCode: "090460",
  },
  {
    bankName: "OMIYE MFB",
    bankCode: "090295",
  },
  {
    bankName: "OPAY",
    bankCode: "100004",
  },
  {
    bankName: "Oakland Microfinance Bank",
    bankCode: "090437",
  },
  {
    bankName: "Ojokoro Mfb",
    bankCode: "090527",
  },
  {
    bankName: "Olowolagba Microfinance Bank",
    bankCode: "090404",
  },
  {
    bankName: "Oraukwu  Microfinance Bank",
    bankCode: "090492",
  },
  {
    bankName: "Oscotech MFB",
    bankCode: "090396",
  },
  {
    bankName: "Ospoly Microfinance Bank",
    bankCode: "090456",
  },
  {
    bankName: "PAGA",
    bankCode: "100002",
  },
  {
    bankName: "PAGE FINANCIALS",
    bankCode: "070008",
  },
  {
    bankName: "PALMPAY",
    bankCode: "100033",
  },
  {
    bankName: "PARKWAY-READYCASH",
    bankCode: "100003",
  },
  {
    bankName: "PARRALEX",
    bankCode: "090004",
  },
  {
    bankName: "PATRICK GOLD",
    bankCode: "090317",
  },
  {
    bankName: "PECAN TRUST MICROFINANCE BANK",
    bankCode: "090137",
  },
  {
    bankName: "PENNYWISE MICROFINANCE BANK",
    bankCode: "090196",
  },
  {
    bankName: "PERSONAL TRUST MICROFINANCE BANK",
    bankCode: "090135",
  },
  {
    bankName: "PETRA MICROFINANCE BANK",
    bankCode: "090165",
  },
  {
    bankName: "PILLAR MFB",
    bankCode: "090289",
  },
  {
    bankName: "PLATINUM MORTGAGE BANK",
    bankCode: "070013",
  },
  {
    bankName: "POLARIS BANK",
    bankCode: "000008",
  },
  {
    bankName: "POLYUWANNA MFB",
    bankCode: "090296",
  },
  {
    bankName: "PRESTIGE MICROFINANCE BANK",
    bankCode: "090274",
  },
  {
    bankName: "PROVIDUS BANK ",
    bankCode: "000023",
  },
  {
    bankName: "PURPLEMONEY MFB",
    bankCode: "090303",
  },
  {
    bankName: "Palmcoast Microfinance bank",
    bankCode: "090497",
  },
  {
    bankName: "Parallex Bank",
    bankCode: "000030",
  },
  {
    bankName: "Parkway MF Bank",
    bankCode: "090390",
  },
  {
    bankName: "Peace Microfinance Bank",
    bankCode: "090402",
  },
  {
    bankName: "Preeminent Microfinance Bank",
    bankCode: "090412",
  },
  {
    bankName: "Premium Trust bank",
    bankCode: "000031",
  },
  {
    bankName: "Prisco  Microfinance Bank",
    bankCode: "090481",
  },
  {
    bankName: "Pristine Divitis Microfinance Bank",
    bankCode: "090499",
  },
  {
    bankName: "Projects Microfinance bank",
    bankCode: "090503",
  },
  {
    bankName: "QUICKFUND MICROFINANCE BANK",
    bankCode: "090261",
  },
  {
    bankName: "RAHAMA MFB",
    bankCode: "090170",
  },
  {
    bankName: "RAND MERCHANT BANK",
    bankCode: "000024",
  },
  {
    bankName: "REFUGE MORTGAGE BANK",
    bankCode: "070011",
  },
  {
    bankName: "REGENT MFB",
    bankCode: "090125",
  },
  {
    bankName: "RELIANCE MFB",
    bankCode: "090173",
  },
  {
    bankName: "RENMONEY MICROFINANCE BANK",
    bankCode: "090198",
  },
  {
    bankName: "REPHIDIM MICROFINANCE BANK",
    bankCode: "090322",
  },
  {
    bankName: "RICHWAY MFB",
    bankCode: "090132",
  },
  {
    bankName: "RIGO Microfinance Bank",
    bankCode: "090433",
  },
  {
    bankName: "RIMA Microfinance Bank",
    bankCode: "090443",
  },
  {
    bankName: "ROYAL EXCHANGE MICROFINANCE BANK",
    bankCode: "090138",
  },
  {
    bankName: "Radalpha Microfinance bank",
    bankCode: "090496",
  },
  {
    bankName: "Rehoboth Microfinance Bank",
    bankCode: "090463",
  },
  {
    bankName: "Resident Fintech Limited",
    bankCode: "110024",
  },
  {
    bankName: "Rima Growth pathway Microfinance Bank ",
    bankCode: "090515",
  },
  {
    bankName: "SAFETRUST",
    bankCode: "090006",
  },
  {
    bankName: "SAGAMU MICROFINANCE BANK",
    bankCode: "090140",
  },
  {
    bankName: "SEAP Microfinance Bank",
    bankCode: "090513",
  },
  {
    bankName: "SEED CAPITAL MICROFINANCE BANK",
    bankCode: "090112",
  },
  {
    bankName: "SEEDVEST MICROFINANCE BANK",
    bankCode: "090369",
  },
  {
    bankName: "SLS  MF Bank",
    bankCode: "090449",
  },
  {
    bankName: "SPARKLE MICROFINANCE BANK",
    bankCode: "090325",
  },
  {
    bankName: "SPAY business",
    bankCode: "110026",
  },
  {
    bankName: "STANBIC IBTC @EASE WALLET",
    bankCode: "100007",
  },
  {
    bankName: "STANBICIBTC BANK",
    bankCode: "000012",
  },
  {
    bankName: "STANDARDCHARTERED",
    bankCode: "000021",
  },
  {
    bankName: "STANFORD MFB",
    bankCode: "090162",
  },
  {
    bankName: "STB Mortgage Bank",
    bankCode: "070022",
  },
  {
    bankName: "STELLAS MICROFINANCE BANK",
    bankCode: "090262",
  },
  {
    bankName: "STERLING BANK",
    bankCode: "000001",
  },
  {
    bankName: "SULSAP MFB",
    bankCode: "090305",
  },
  {
    bankName: "SUNTRUST BANK",
    bankCode: "000022",
  },
  {
    bankName: "SafeHaven Microfinance Bank",
    bankCode: "090286",
  },
  {
    bankName: "Safegate Microfinance Bank",
    bankCode: "090485",
  },
  {
    bankName: "Shalom Microfinance Bank",
    bankCode: "090502",
  },
  {
    bankName: "Shepherd Trust Microfinance Bank",
    bankCode: "090401",
  },
  {
    bankName: "SmartCash Payment Service bank",
    bankCode: "120004",
  },
  {
    bankName: "Solid Allianze MFB",
    bankCode: "090506",
  },
  {
    bankName: "Solidrock Microfinance bank",
    bankCode: "090524",
  },
  {
    bankName: "Spectrum Microfinance Bank",
    bankCode: "090436",
  },
  {
    bankName: "Standard MFB",
    bankCode: "090182",
  },
  {
    bankName: "Stockcorp  Microfinance Bank",
    bankCode: "090340",
  },
  {
    bankName: "Sunbeam Microfinance Bank",
    bankCode: "090302",
  },
  {
    bankName: "Support MF Bank",
    bankCode: "090446",
  },
  {
    bankName: "TAGPAY",
    bankCode: "100023",
  },
  {
    bankName: "TAJ BANK",
    bankCode: "000026",
  },
  {
    bankName: "TCF",
    bankCode: "090115",
  },
  {
    bankName: "TEASYMOBILE",
    bankCode: "100010",
  },
  {
    bankName: "TF MICROFINANCE BANK",
    bankCode: "090373",
  },
  {
    bankName: "TITAN TRUST BANK",
    bankCode: "000025",
  },
  {
    bankName: "TITAN-PAYSTACK MICROFINANCE BANK",
    bankCode: "100039",
  },
  {
    bankName: "TRIDENT MICROFINANCE BANK",
    bankCode: "090146",
  },
  {
    bankName: "TRUST MFB",
    bankCode: "090327",
  },
  {
    bankName: "TRUSTBANC J6 MICROFINANCE BANK LIMITED",
    bankCode: "090123",
  },
  {
    bankName: "TRUSTBOND",
    bankCode: "090005",
  },
  {
    bankName: "TRUSTFUND MICROFINANCE BANK",
    bankCode: "090276",
  },
  {
    bankName: "TajWallet",
    bankCode: "080002",
  },
  {
    bankName: "Tangerine Money MFB",
    bankCode: "090426",
  },
  {
    bankName: "Triple A Microfinance bank",
    bankCode: "090525",
  },
  {
    bankName: "U AND C MFB",
    bankCode: "090315",
  },
  {
    bankName: "UNAAB MFB",
    bankCode: "090331",
  },
  {
    bankName: "UNIBEN MICROFINANCE BANK",
    bankCode: "090266",
  },
  {
    bankName: "UNICAL MFB",
    bankCode: "090193",
  },
  {
    bankName: "UNIIBADAN Microfinance Bank",
    bankCode: "090461",
  },
  {
    bankName: "UNION BANK",
    bankCode: "000018",
  },
  {
    bankName: "UNITED BANK FOR AFRICA",
    bankCode: "000004",
  },
  {
    bankName: "UNITY BANK",
    bankCode: "000011",
  },
  {
    bankName: "UNN MFB",
    bankCode: "090251",
  },
  {
    bankName: "Uhuru Microfinance bank",
    bankCode: "090517",
  },
  {
    bankName: "Umuchinemere Procredit Microfinance Bank",
    bankCode: "090514",
  },
  {
    bankName: "Umunnachi Microfinance Bank",
    bankCode: "090510",
  },
  {
    bankName: "UniUyo Microfinance Bank",
    bankCode: "090338",
  },
  {
    bankName: "Unilag  Microfinance Bank",
    bankCode: "090452",
  },
  {
    bankName: "Unimaid Microfinance Bank",
    bankCode: "090464",
  },
  {
    bankName: "Uzondu MF Bank",
    bankCode: "090453",
  },
  {
    bankName: "VFD MFB",
    bankCode: "090110",
  },
  {
    bankName: "VIRTUE MFB",
    bankCode: "090150",
  },
  {
    bankName: "VISA MICROFINANCE BANK",
    bankCode: "090139",
  },
  {
    bankName: "VTNetwork",
    bankCode: "100012",
  },
  {
    bankName: "Vas2nets Limited",
    bankCode: "110015",
  },
  {
    bankName: "Verdant Microfinance Bank",
    bankCode: "090474",
  },
  {
    bankName: "WEMA BANK",
    bankCode: "000017",
  },
  {
    bankName: "WETLAND MFB",
    bankCode: "090120",
  },
  {
    bankName: "WinView Bank",
    bankCode: "090419",
  },
  {
    bankName: "XSLNCE MICROFINANCE BANK",
    bankCode: "090124",
  },
  {
    bankName: "YCT Microfinance Bank",
    bankCode: "090466",
  },
  {
    bankName: "YES MFB",
    bankCode: "090142",
  },
  {
    bankName: "Yello Digital financial services",
    bankCode: "110027",
  },
  {
    bankName: "Yobe MFB",
    bankCode: "090252",
  },
  {
    bankName: "ZENITH BANK PLC",
    bankCode: "000015",
  },
  {
    bankName: "ZWallet",
    bankCode: "100034",
  },
  {
    bankName: "Zikora Microfinance bank",
    bankCode: "090504",
  },
  {
    bankName: "unilorin Microfinance Bank",
    bankCode: "090341",
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
